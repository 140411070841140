import mediaConfig, { availableAudioFileTypes, availableImageFileTypes, availableFileIcons } from './config'
import _ from 'lodash'
import { localisationResolver } from '@/plugins/Trans'

export const configResolve = (path) => {
    return _.get(mediaConfig, path, false)
}

export const isViewable = (src) => {
    if(!src) {
        return false
    }

    return isYoutubeLink(src) ||
        isImage(src) ||
        isPDF(src) ||
        isAudio(src) ||
        isMSDocument(src)
}

export const uploadComponent = (type) => {
    return configResolve(`${ type }.upload.component`)
}

export const extension = (src) => {
    if(!src) {
        return null
    }

    return src.split('.').slice(-1)[0].toLowerCase()
}

export const isImage = (src) => {
    if(!src) return false

    return availableImageFileTypes()
        .includes(extension(src)) ||
        src.includes('blob:')
}

export const isImageExists = async (src) => {
    if(!src) return false

    let flag = false

    try {
        const img = new Image()
        img.src = src

        if(img.complete) {
            flag = true
        } else {
            img.onload = () => {
                flag = true
            }

            img.onerror = () => {
                flag = false
            }
        }
    } catch (error) {
        return flag
    }
}

export const isAudio = (src) => {
    if(!src) return false

    return availableAudioFileTypes().includes(extension(src))
}

export const isPDF = (src) => {
    if(!src) return false

    return extension(src.toLowerCase()) === 'pdf'
}

export const isYoutubeLink = (src) => {
    if(!src) return false

    const re = /^(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/

    return re.test(src)
}

export const isMSWord = (src) => {
    if(!src) return false

    return [ 'doc', 'docx' ].includes(extension(src))
}

export const isMSPowerpoint = (src) => {
    if(!src) return false

    return [ 'ppt', 'pptx' ].includes(extension(src))
}

export const isMSExel = (src) => {
    if(!src) return false

    return [ 'xls', 'xlsx' ].includes(extension(src))
}

export const isMSDocument = (src) => {
    if(!src) return false

    return isMSWord(src) ||
        isMSPowerpoint(src) ||
        isMSExel(src)
}

export const mediaTypeLabel = (src) => {
    if(!src) return null

    if(isImage(src)) {
        return localisationResolver('Image')
    }

    if(isAudio(src)) {
        return [
            localisationResolver('Audio'),
            localisationResolver('Files')
        ].join(' ')
    }

    if(isPDF(src)) {
        return [
            'PDF',
            localisationResolver('Files')
        ].join(' ')
    }

    if(isMSWord(src)) {
        return 'MS Word'
    }

    if(isMSPowerpoint(src)) {
        return 'MS Powerpoint'
    }

    if(isMSExel(src)) {
        return 'MS Exel'
    }

    return null
}

export const iconPath = function (src) {
    if(!src) {
        return null
    }

    let iconPath

    if(src.includes('drive.google.com')) {
        iconPath = 'google-icons/google_drive.svg'
    } else if(src.includes('docs.google.com/spreadsheets')) {
        iconPath = 'google-icons/google_sheets.svg'
    } else if(src.includes('docs.google.com/document')) {
        iconPath = 'google-icons/google_docs.svg'
    } else if(src.includes('docs.google.com/presentation')) {
        iconPath = 'google-icons/google_slides.svg'
    } else if(src.includes('docs.google.com/forms')) {
        iconPath = 'google-icons/google_forms.svg'
    } else if(src.includes('sites.google.com')) {
        iconPath = 'google-icons/google_sites.svg'
    } else if(isYoutubeLink(src)) {
        iconPath = 'misc-icons/youtube.svg'
    } else {
        const extensionResolve = extension(src)

        if(availableFileIcons.includes(extensionResolve)) {
            iconPath = `file-icons/${ extensionResolve }.svg`
        }
    }

    return iconPath ? require(`@/assets/icons/${ iconPath }`) : null
}
