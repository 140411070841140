/**
 * То же, что и другие ответы выше...

 <iframe src="https://docs.google.com/gview?url={magical url that works}"></iframe>

 кроме magical url that works есть https://drive.google.com/uc?id=<docId>&embedded=true.

 Google Диск/Документы предоставляет кучу разных URL-адресов:

 https://drive.google.com/open?id=<docId> Поделиться ссылкой.
 https://docs.google.com/document/<docId>/edit Открыть в Google Диске.
 https://docs.google.com/document/d/<docId>/view То же, что и "редактировать" выше. Я думаю.
 https://docs.google.com/document/d/<docId>/pub?embedded=true Для вложения в iframe, если вы File -> Publish to the web...
 https://drive.google.com/uc?export=download&id=<docId> Прямая ссылка для скачивания.

 Я столкнулся с этим решением после кучи проб и ошибок с разными ссылками. Надеюсь это поможет!
 */

import store from '@/store'

export const availableFileIcons = [
    '7z', 'aa', 'aac', 'ai', 'aif', 'avi',
    'doc', 'docx',
    'eps', 'example', 'exe',
    'flv', 'form',
    'htm', 'html',
    'ico', 'image', 'img', 'inf', 'ini', 'iso',
    'jpe', 'jpeg', 'jpg', 'json',
    'key',
    'less', 'link', 'log',
    'm2v', 'm3u', 'm3u8', 'm4a', 'm4r', 'm4v', 'md', 'mdf', 'mid', 'midi', 'mkv', 'mov', 'mp2', 'mp3', 'mp4', 'mpa', 'mpeg', 'mpg',
    'nested',
    'password', 'pdf', 'php', 'png', 'ppsx', 'ppt', 'psd',
    'rar', 'rdf', 'rtf', 'rub',
    'sass', 'scss', 'sh', 'sitemap', 'sql', 'svg', 'swf', 'sys',
    'table', 'tar', 'tex', 'tga', 'tif', 'tiff', 'torrent', 'tree', 'ttf', 'txt',
    'user',
    'wav',
    'xlm', 'xls', 'xlsm', 'xlsx', 'xml', 'csv',
    'yml',
    'zip'
]

export const availableAudioFileTypes = () => {
    return store.getters['media/availableAudioTypes']
}

export const availableImageFileTypes = () => {
    return store.getters['media/availableImageTypes']
}

export const mediaTypes = Object.freeze({
    FOLDER: 'folder',
    FILE: 'file',
    GOOGLE_DOCUMENT: 'google_document',
    GOOGLE_FORMS: 'google_forms',
    GOOGLE_PRESENTATION: 'google_presentation',
    GOOGLE_SPREADSHEETS: 'google_spreadsheets',
    GOOGLE_SITES: 'google_sites',
    LINK: 'link',
    YOUTUBE: 'youtube'
})

export const uploadStatus = Object.freeze({
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
})

export default Object.freeze({
    youtube: {
        view: {
            component: 'youtube-view'
        }
    },
    file: {
        view: {
            component: 'file-view'
        },
        upload: {
            component: 'upload-file-form'
        }
    }
})
