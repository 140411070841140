/* eslint-disable */
import { localisationResolver } from '@/plugins/Trans'

const ConfirmationSets = class {
    static deleteSet(options = {}) {

        const defaultSet = {
            title: null,
            message: localisationResolver('confirmation.deletion'),
            buttonTrueText: localisationResolver('Delete'),
            buttonTrueColor: 'error',
            buttonFalseText: localisationResolver('Cancel')
        }

        return Object.assign(defaultSet, options)
    }

    static deleteAndCannotUndoneSet(options = {}) {

        const defaultSet = {
            message: `${localisationResolver('confirmation.deletion')} <br> ${localisationResolver('confirmation.cannot_undone')}`
        }
        const deleteSet = ConfirmationSets.deleteSet()

        return Object.assign(deleteSet, defaultSet, options)
    }
}

export default ConfirmationSets
