<template>
    <v-card
        tile
        flat
        :loading="loading"
        :disabled="loading"
        class="media-table"
    >
        <div
            v-if="!hideDefaultActions"
            class="d-flex align-center px-2 py-2"
        >
            <span v-if="title" class="subtitle-2 font-weight-bold mx-2">{{ title }}</span>
            <media-table-breadcrumbs
                :is-media-view-search="isMediaViewSearch"
                :is-media-view-trashed="isMediaViewTrashed"
                :shared-uuid="sharedUuid"
                :parent="parent"
                :items="breadcrumbs"
                class="text-truncate"
                @click="fetchDocuments"
                @comback="onComback"
            />
            <media-table-search-form
                v-if="isMediaViewSearch"
                @input="fetchDocuments"
                @clear="clearUpData"
                class="flex-fill pl-4"
            />
            <v-spacer/>
            <v-btn
                v-if="!isMediaViewSearch && !isMediaViewTrashed && searchable"
                icon
                @click="toMediaView('search')"
            >
                <w-icon
                    value="SEARCH"
                />
            </v-btn>
            <v-divider
                v-if="!isMobile && !isMediaViewSearch && searchable && showActionsMenu"
                vertical
                class="mx-2"
            />
            <slot name="actions">
                <w-actions-menu
                    v-if="showActionsMenu"
                    icon="ADD"
                    :activator="{ color: 'primary' }"
                    :inline="!isMobile"
                    :inline-icon="actionsMenu.length > 1"
                    :items="actionsMenu"
                />
            </slot>
            <v-divider
                v-if="showDividerBeforeCommonMenu && showActionsMenu"
                vertical
                class="ml-2 mr-1"
            />
            <w-actions-menu
                v-if="showCommonMenu"
                :inline="!isMobile"
                :inline-icon="commonMenu.length > 1"
                :items="commonMenu"
            />
        </div>
        <v-divider
            v-if="!hideDefaultActions"
        />
        <div
            v-if="!hideDefaultHeader"
            class="d-flex align-center py-2 px-5 theme-gray-bg"
        >
            <media-table-header-item
                value="title"
                @click="onOrderBy"
            >
                {{ $trans('Title') }}
            </media-table-header-item>
            <v-spacer/>
            <media-table-header-item
                v-if="!isMobile && !hideAuthor"
                value="author.last_name"
                :width="labelSize*4"
                class="mr-3"
                @click="onOrderBy"
            >
                {{ $trans('Author') }}
            </media-table-header-item>
            <media-table-header-item
                v-if="!isMobile && !hideSize"
                :width="labelSize * 3"
                value="size"
                class="mr-3"
                @click="onOrderBy"
            >
                {{ $trans('Size') }}
            </media-table-header-item>
            <media-table-header-item
                v-if="!isMobile && !hideDate"
                value="updated_at"
                :width="labelSize*3"
                @click="onOrderBy"
            >
                {{ $trans('Updated at') }}
            </media-table-header-item>
            <div
                v-if="editable"
                :style="{width: `${labelSize}px`}">
                &nbsp;
            </div>
        </div>
        <v-divider
            v-if="!hideDefaultHeader"
        />
        <v-card-text v-bind="listWrapperBindings">
            <v-list
                :key="`listcomponentkey-${listComponentKey}`"
                dense
            >
                <media-table-empty-content
                    v-if="collection.length === 0 && !hideEmptyText"
                />
                <template v-for="(item, index) in collection">
                    <v-divider
                        v-if="index > 0"
                        :key="`divider-${index}`"
                    />
                    <media-table-list-item
                        :key="item.uuid"
                        :item="item"
                        :label-size="labelSize"
                        :actions="itemActionsMenu(item)"
                        :selectable="selectable"
                        :selectable-types="selectableTypes"
                        :editable="editable"
                        :hide-author="hideAuthor"
                        :hide-size="hideSize"
                        :hide-date="hideDate"
                        :viewable="viewable"
                        :disabled-item="disabledItems"
                        :is-media-view-shared="isMediaViewShared"
                        @click="onClickItem"
                        @checked="onCheckedItem"
                        @unchecked="onUncheckedItem"
                        @detach:shared="onDetachSharedDocument"
                    />
                </template>
            </v-list>
        </v-card-text>
        <v-divider v-if="isFooter"/>
        <div v-if="$slots.footer" class="px-4 py-3">
            <slot name="footer"></slot>
        </div>
        <media-dialog
            v-model="isViewDialog"
            :title="selectedItem.title"
            :href="selectedItem.url"
            :download="selectedItem.download"
            :type="selectedItem.type"
            :width="dialogWidth"
        >
            <media-view
                :item="selectedItem"
                fullscreen
            />
        </media-dialog>
        <w-aside-drawer
            v-model="isDetailsDialog"
            :title="$trans('Details')"
        >
            <media-details
                :item="detailsItem"
                editable
            />
        </w-aside-drawer>
        <media-edit-form-dialog
            v-model="isEditFormDialog"
            :item="selectedItem"
            @success="fetchDocuments"
        />
        <media-upload-form-dialog
            v-model="isUploadFormDialog"
            :media-type="uploadMediaType"
            :parent="currentFolderUuid"
            @success="fetchDocuments"
            @cancel="isUploadFormDialog = false"
        />
        <media-picker
            v-if="isMediaViewShared"
            v-model="isPicker"
            :payload="pickerPayload"
            :selectable-types="selectableTypes"
            @select="onShareDocuments"
        />
        <media-explorer
            v-if="editable && !isMediaViewShared"
            v-model="isExplorer"
            :title="$trans('Move to')"
            :action-text="$trans('Move here')"
            :disabled-action="isExplorerActionDisabled"
            @loaded="onLoadedExplorer"
            @select="onMoveItem"
        />
    </v-card>
</template>

<script>
import _ from 'lodash'
import { isViewable } from '@apps/media/tools'
import {
    cleanUpDocuments,
    deleteDocument, detachSharedDocument,
    getDocuments,
    removeDocument,
    restoreDocument, shareDocuments,
    updateDocument
} from '@apps/media/api'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { mediaTypes } from '@apps/media/tools/config'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import mediaTypeLabelFilterMixin from '@apps/media/mixins/mediaTypeLabelFilterMixin'
export default {
    name: 'MediaTable',
    mixins: [
        mobileBreakpointChecker,
        mediaTypeLabelFilterMixin
    ],
    components: {
        MediaExplorer: () => import('@apps/media/components/MediaExplorer'),
        MediaPicker: () => import('@apps/media/components/MediaPicker'),
        MediaTableHeaderItem: () => import('@apps/media/components/MediaTable/MediaTableHeaderItem'),
        MediaDetails: () => import('@apps/media/components/MediaDetails'),
        MediaTableSearchForm: () => import('@apps/media/components/MediaTable/MediaTableSearchForm'),
        MediaTableListItem: () => import('@apps/media/components/MediaTable/MediaTableListItem'),
        MediaTableEmptyContent: () => import('@apps/media/components/MediaTable/MediaTableEmptyContent'),
        MediaTableBreadcrumbs: () => import('@apps/media/components/MediaTable/MediaTableBreadcrumbs'),
        MediaUploadFormDialog: () => import('@apps/media/components/MediaUploadForm/UploadFormDialog'),
        MediaEditFormDialog: () => import('@apps/media/components/MediaEditForm/MediaEditFormDialog'),
        MediaDialog: () => import('@apps/media/components/MediaDialog'),
        MediaView: () => import('@apps/media/components/MediaView'),
    },
    props: {
        value: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        },
        sharedType: {
            type: String,
            default: null
        },
        sharedUuid: {
            type: String,
            default: null
        },
        height: {
            type: [ String, Number ],
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectableTypes: {
            type: Array,
            default: null
        },
        picker: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        disabledItems: {
            type: Boolean,
            default: false
        },
        hideAuthor: {
            type: Boolean,
            default: false
        },
        hideSize: {
            type: Boolean,
            default: false
        },
        hideDate: {
            type: Boolean,
            default: false
        },
        hideDefaultActions: {
            type: Boolean,
            default: false
        },
        hideDefaultHeader: {
            type: Boolean,
            default: false
        },
        hideEmptyText: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        listWrapperBindings() {
            const bindings = {
                class: [ 'pa-0' ]
            }

            if(this.height) {
                bindings.style = {
                    height: this.height + 'px'
                }
            }

            return bindings
        },
        isFooter() {
            return !!this.$slots.footer
        },
        sharedPayload() {
            return {
                shared_type: this.sharedType,
                shared_uuid: this.sharedUuid
            }
        },
        pickerPayload() {
            return {
                author: this.$auth.user.uuid
            }
        },
        isMediaViewShared() {
            return !!this.sharedType &&
                !!this.sharedUuid
        },
        isMediaViewSearch() {
            return this.mediaView === 'search'
        },
        isMediaViewTrashed() {
            return this.mediaView === 'trashed'
        },
        showActionsMenu() {
            return this.actionsMenu.length > 0 &&
                !this.isMediaViewSearch
        },
        showDividerBeforeCommonMenu() {
            return !this.isMobile &&
                this.commonMenu.length > 0 &&
                !this.isMediaViewSearch
        },
        showCommonMenu() {
            return this.commonMenu.length > 0 && !this.isMediaViewSearch
        },
        currentFolderUuid() {
            if(!this.parent) {
                return null
            }

            return this.parent.uuid
        },
        commonMenu() {
            if(this.isMediaViewTrashed) {
                return []
            }

            const items = []

            if(this.editable && !this.isMediaViewShared) {
                items.push({
                    text: this.$trans('Trash'),
                    icon: 'TRASH',
                    color: 'error',
                    on: {
                        click: () => {
                            this.toMediaView('trashed')
                            this.fetchDocuments({ trashed: true })
                        }
                    }
                })

                items.push({
                    text: this.$trans('Update'),
                    icon: 'REFRESH',
                    color: 'warning',
                    on: {
                        click: () => {
                            this.fetchDocuments()
                        }
                    }
                })
            }

            return items
        },
        actionsMenu() {
            if(!this.editable) {
                return []
            }

            const items = []

            if(this.isMediaViewTrashed) {
                items.push({
                    text: this.$trans('Clean up trash'),
                    icon: 'CLEAN_UP',
                    color: 'error',
                    disabled: this.collection.length === 0,
                    on: {
                        click: () => {
                            this.onCleanUpDocuments()
                        }
                    }
                })
            }

            if(this.editable && this.isMediaViewShared) {
                items.push({
                    text: this.$trans('Add documents'),
                    icon: 'ADD',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.isPicker = true
                        }
                    }
                })

                // items.push({
                //     text: this.$trans('Update'),
                //     icon: 'REFRESH',
                //     color: 'warning',
                //     on: {
                //         click: () => {
                //             this.fetchSharedDocuments()
                //         }
                //     }
                // })
            }

            if(this.editable &&
                !this.isMediaViewShared &&
                !this.isMediaViewSearch &&
                !this.isMediaViewTrashed
            ) {
                items.push({
                    text: this.$trans('Create folder'),
                    icon: 'FOLDER_PLUS',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.uploadMediaType = mediaTypes.FOLDER
                            this.isUploadFormDialog = true
                        }
                    }
                })

                items.push({
                    text: this.$trans('Upload files'),
                    icon: 'UPLOAD',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.uploadMediaType = mediaTypes.FILE
                            this.isUploadFormDialog = true
                        }
                    }
                })

                items.push({
                    text: this.$trans('Add youtube video'),
                    icon: 'YOUTUBE',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.uploadMediaType = mediaTypes.YOUTUBE
                            this.isUploadFormDialog = true
                        }
                    }
                })

                items.push({
                    text: this.$trans('Add link'),
                    icon: 'LINK',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.uploadMediaType = mediaTypes.LINK
                            this.isUploadFormDialog = true
                        }
                    }
                })

                // items.push({
                //     text: this.$trans('Add file from google drive'),
                //     icon: 'GOOGLE_DRIVE',
                //     color: 'primary',
                //     on: {
                //         click: () => {
                //             this.uploadMediaType = 'google-drive'
                //             this.isUploadFormDialog = true
                //         }
                //     }
                // })
            }

            return items
        }
    },
    watch: {
        picker(flag) {
            this.isPicker = flag
        },
        isPicker(flag) {
            this.$emit('update:picker', flag)
        },
        isMediaViewSearch(flag) {
            if(flag) {
                this.clearUpData()
            }
        },
        isViewDialog(value) {
            if(!value) {
                this.selectedItem = { title: null }
            }
        },
        isDetailsDialog(value) {
            this.resetList()

            if(!value) {
                this.detailsItem = null
            }
        },
        isEditFormDialog(value) {
            if(!value) {
                this.selectedItem = { title: null }
            }
        },
        collection() {
            this.resetList()
        },
        value(selectedItems) {
            if(selectedItems.length === 0) {
                this.resetList()
            }
        },
        sharedUuid() {
            if(this.isMediaViewShared) {
                this.fetchSharedDocuments()
            }
        }
    },
    data() {
        return {
            listComponentKey: 0,
            loading: false,
            componentKey: 0,
            isEditFormDialog: false,
            isUploadFormDialog: false,
            uploadMediaType: null,
            isViewDialog: false,
            isPicker: false,
            isDetailsDialog: false,
            dialogWidth: 768,
            labelSize: 30,
            selectedItem: { title: null },
            detailsItem: null,
            formErrors: {},
            mediaView: null,
            parent: null,
            breadcrumbs: [],
            unsortedCollection: [],
            collection: [],
            selectedItems: [],
            isExplorer: false,
            isExplorerActionDisabled: false
        }
    },
    mounted() {
        if(!this.isMediaViewSearch) {
            if(this.isMediaViewShared) {
                this.fetchSharedDocuments()
            } else {
                this.fetchDocuments()
            }
        } else {
            this.clearUpData()
        }
    },
    methods: {
        clearUpData() {
            this.collection = []
            this.breadcrumbs = []
            this.parent = null
        },
        toMediaView(view) {
            this.mediaView = view
        },
        onComback() {
            this.mediaView = null
            this.parent = null

            if(this.isMediaViewShared) {
                this.fetchSharedDocuments()
            } else {
                this.fetchDocuments()
            }
        },
        resetList() {
            if(this.selectedItems.length > 0) {
                this.listComponentKey++
                this.selectedItems = []
            }

            this.$emit('input', this.selectedItems)
        },
        async fetchDocuments(payload) {
            this.loading = true

            payload = payload || {}

            if(this.isMediaViewTrashed) {
                payload.trashed = true
            }

            if(!Object.keys(payload).includes('parent') && this.parent) {
                payload.parent = this.parent.uuid
            }

            try {
                const response = await getDocuments({ ...this.payload, ...payload })

                this.parent = response.data.parent
                this.breadcrumbs = response.data.breadcrumbs

                this.collection = response.data.data
                    ? response.data.data.map((o, i) => {
                        o.__defaultIndex = i
                        return o
                    })
                    : []

                this.$emit('loaded', this.parent, this.collection)
            } finally {
                this.loading = false
            }
        },
        fetchSharedDocuments() {
            if(this.sharedUuid === '#') {
                return
            }

            this.fetchDocuments(this.sharedPayload)
        },
        onOrderBy(field, direction) {
            const iterates = direction
                ? [ field ]
                : [ '__defaultIndex' ]

            const orders = direction
                ? [ direction ]
                : [ 'asc' ]

            this.collection = _.orderBy(this.collection, iterates, orders)
        },
        onClickItem(item) {
            if(!item || this.isMediaViewTrashed) {
                return
            }

            if(item.type === mediaTypes.FOLDER) {
                this.mediaView = null
                this.fetchDocuments({ parent: item.uuid })
            } else if(this.viewable && isViewable(item.src)) {
                this.selectedItem = item
                this.componentKey++

                this.isViewDialog = true
            } else if(this.viewable) {
                window.open(item.src, '_blank').focus()
            }
        },
        onCheckedItem(item) {
            this.selectedItems.push(item)
            this.$emit('input', this.selectedItems)
        },
        onUncheckedItem(item) {
            this.selectedItems = this.selectedItems.filter(o => o !== item.uuid)
            this.$emit('input', this.selectedItems)
        },
        onLoadedExplorer(folder) {
            const dist = _.get(folder, 'uuid', null)
            const target = _.get(this.selectedItem, 'parent_uuid')

            this.isExplorerActionDisabled = dist === target
        },
        async onMoveItem(folder) {
            this.$lprogress.begin()

            try {
                const payload = {
                    uuid: this.selectedItem.uuid,
                    parent: folder ? folder.uuid : null
                }

                await updateDocument(payload)

                await this.fetchDocuments()
            } finally {
                this.$lprogress.end()
            }
        },
        async onShareDocuments(items) {
            if(this.sharedUuid === '#') {
                items.forEach(item => {
                    item.shared = [{
                        shared_uuid: this.sharedUuid,
                        shared_type: this.sharedType,
                        shared_by: this.$auth.user.uuid,
                        media_uuid: item.uuid
                    }]

                    this.collection.push(item)
                })

                this.$emit('select', this.collection)

                return
            }

            const data = []

            items.forEach(item => {
                data.push({
                    shared_uuid: this.sharedUuid,
                    shared_type: this.sharedType,
                    media_uuid: item.uuid
                })
            })

            try {
                this.$lprogress.begin()

                await shareDocuments(data)
                await this.fetchSharedDocuments()
            } finally {
                this.$lprogress.end()
            }
        },
        async onDetachSharedDocument(item) {
            if(this.sharedUuid === '#') {
                this.collection = this.collection.filter(o => o.uuid !== item.uuid)
                this.selectedItems = [].concat(this.collection)

                this.$emit('select', this.collection)

                return
            }

            this.loading = true

            try {
                await detachSharedDocument({
                    media_uuid: item.uuid,
                    shared_type: this.sharedType,
                    shared_uuid: this.sharedUuid
                })

                this.fetchSharedDocuments()
            } finally {
                this.loading = false
            }
        },
        itemActionsMenu(item) {
            const actions = []

            if(!this.isMediaViewTrashed) {
                actions.push({
                    text: this.$trans('Details'),
                    icon: 'INFO',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.detailsItem = item
                            this.isDetailsDialog = true
                        }
                    }
                })

                actions.push({
                    text: this.$trans('Rename'),
                    icon: 'EDIT',
                    color: 'success',
                    on: {
                        click: () => {
                            this.selectedItem = item
                            this.isEditFormDialog = true
                        }
                    }
                })

                if(item.type !== mediaTypes.FOLDER) {
                    actions.push({
                        text: this.$trans('Move'),
                        icon: 'MOVE',
                        color: 'primary',
                        on: {
                            click: () => {
                                this.selectedItem = item
                                this.isExplorer = true
                            }
                        }
                    })
                }

                actions.push({
                    text: this.$trans('Delete'),
                    icon: 'DELETE',
                    color: 'error',
                    on: {
                        click: async () => {
                            await this.onRemoveDocument(item)
                        }
                    }
                })
            } else {
                actions.push({
                    text: this.$trans('Restore'),
                    icon: 'RESTORE',
                    color: 'warning',
                    on: {
                        click: () => {
                            this.onRestoreDocument(item)
                        }
                    }
                })

                actions.push({
                    text: this.$trans('Delete'),
                    icon: 'CLOSE',
                    color: 'error',
                    on: {
                        click: async () => {
                            await this.onDeleteDocument(item)
                        }
                    }
                })
            }

            return actions
        },
        async onUpdateDocument(data, item) {
            this.isFormDialogDisabled = true

            const payload = {
                ...data,
                uuid: item.uuid,
                ...this.payload
            }

            try {
                await updateDocument(payload)
                await this.fetchDocuments()
                this.isFormDialog = false
            } catch (error) {
                this.formErrors = _.get(error, 'response.data.errors', {})
            } finally {
                this.isFormDialogDisabled = false
            }
        },
        async onRemoveDocument(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${ this.$trans('Delete') } ${ item.title }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await removeDocument({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchDocuments()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onRestoreDocument(item) {
            this.loading = true

            try {
                await restoreDocument({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchDocuments()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onDeleteDocument(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${ this.$trans('Delete') } ${ item.title }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await deleteDocument({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchDocuments()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onCleanUpDocuments() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${ this.$trans('Clean up trash') }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await cleanUpDocuments(this.payload)
                await this.fetchDocuments()
            } catch (error) {
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>
.media-table {
    &__row {
        &:hover {
            color: var(--v-anchor-base) !important;

            .text--primary {
                color: var(--v-anchor-base) !important;
            }
        }
    }
}
</style>
