import { mediaTypeLabel } from '@apps/media/tools'
import { localisationResolver } from '@/plugins/Trans'

export default {
    filters: {
        mediaTypeLabel(item) {
            const label = mediaTypeLabel(item.src)

            return label || localisationResolver(`media.types.${item.type}`)
        }
    }
}
